import React, { useState } from "react"
import PropTypes from "prop-types"
import SEO from "../components/SEO"
import { graphql } from "gatsby"
import { Transition } from "@headlessui/react"
import Layout from "../components/Layout"
import ImageGallery from "react-image-gallery"

export const pageQuery = graphql`
  query ProductPageQuery($id: String!) {
    product: graphCmsProduct(id: { eq: $id }) {
      price
      name
      image {
        localFile {
          publicURL
          childImageSharp {
            fullSize: fixed(width: 400, height: 400, quality: 70) {
              srcWebp
            }
            thumbnail: fixed(width: 80, height: 80, quality: 60) {
              srcWebp
            }
          }
        }
      }
      category {
        name
      }
      description {
        text
      }
      reviews {
        name
        message
        rating
        publishedAt
      }
      sizes
      sizesAndPrices
      quantity
    }
  }
`

const ProductPage = ({ data: { product }, location }) => {
  const [showDescription, setshowDescription] = useState(true)
  const [showReviews, setshowReviews] = useState(false)
  const [showIngredients, setshowIngredients] = useState(false)
  const [activeTab, setActiveTab] = useState("Description")

  const productSizes = Object.entries(product.sizesAndPrices)
  // console.log()
  const [activeSize, setActiveSize] = useState(productSizes[0][0])
  const [price, setPrice] = useState(productSizes[0][1])

  const images = product.image?.map(image => {
    return {
      original: `${location.origin}/${image.localFile.childImageSharp.fullSize.srcWebp}`,
      thumbnail: `${location.origin}/${image.localFile.childImageSharp.thumbnail.srcWebp}`,
      originalClass:
        "lg:h-auto h-64 object-cover object-center rounded gallery-slide",
      thumbnailClass: "object-cover object-center rounded",
    }
  })
  return (
    <Layout>
      <SEO
        title={product.name}
        desc={product.description.text}
        banner={product.image[0]?.localFile.publicURL}
        price={Object.values(product.sizesAndPrices)[0]}
        type="product"
      />
      <section className="text-gray-700 body-font overflow-hidden">
        <div className="container px-5 py-24 mx-auto">
          <div className="lg:w-4/5 mx-auto flex flex-wrap">
            {images.length > 1 ? (
              <ImageGallery
                items={images}
                thumbnailPosition="left"
                showPlayButton={false}
                showFullscreenButton={false}
                additionalClass="lg:w-1/2 w-full mb-3"
                lazyLoad={true}
              />
            ) : (
              <img
                alt="ecommerce"
                className="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded"
                src={
                  product.image[0]
                    ? product.image[0].localFile.childImageSharp.fullSize
                        .srcWebp
                    : "https://dummyimage.com/400x400"
                }
              />
            )}

            <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mb-6 lg:mb-0">
              <h2 className="text-sm title-font text-gray-500 tracking-widest">
                {product.category ? product.category.name : ""}
              </h2>
              <h1 className="text-gray-900 text-3xl title-font font-medium mb-4">
                {product.name}
              </h1>
              <div className="flex mb-4">
                <a
                  role="button"
                  // className="flex-grow text-olive-700 border-olive-700 border-b-2 py-2 text-lg px-1"
                  className={
                    activeTab === "Description"
                      ? "flex-grow cursor-pointer text-olive-700 border-olive-700 border-b-2 py-2 text-lg px-1"
                      : "flex-grow cursor-pointer border-b-2 py-2 text-lg px-1"
                  }
                  onClick={() => {
                    setActiveTab("Description")
                    setshowDescription(true)
                    setshowIngredients(false)
                    setshowReviews(false)
                  }}
                >
                  Description
                </a>
                {product.reviews.length > 0 && (
                  <a
                    role="button"
                    className={
                      activeTab === "Reviews"
                        ? "flex-grow cursor-pointer text-olive-700 border-olive-700 border-b-2 py-2 text-lg px-1"
                        : "flex-grow cursor-pointer border-b-2 py-2 text-lg px-1"
                    }
                    onClick={() => {
                      setActiveTab("Reviews")
                      setshowDescription(false)
                      setshowIngredients(false)
                      setshowReviews(true)
                    }}
                  >
                    Reviews
                  </a>
                )}
                {product.ingredients && (
                  <a
                    role="button"
                    className={
                      activeTab === "Ingredients"
                        ? "flex-grow cursor-pointer text-olive-700 border-olive-700 border-b-2 py-2 text-lg px-1"
                        : "flex-grow cursor-pointer border-b-2 py-2 text-lg px-1"
                    }
                    onClick={() => {
                      setActiveTab("Ingredients")
                      setshowDescription(false)
                      setshowIngredients(true)
                      setshowReviews(false)
                    }}
                  >
                    Ingredients
                  </a>
                )}
              </div>
              <Transition
                show={showDescription}
                enter="ease-linear delay-100 duration-100"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-50"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <p className="leading-relaxed mb-4">
                  {product.description && product.description.text}
                </p>
              </Transition>
              <Transition
                show={showReviews}
                enter="ease-in-out delay-100 duration-100"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-50"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                {/* <p className="leading-relaxed mb-4">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Sed enim ut sem viverra aliquet eget sit amet. Augue ut lectus
                  arcu bibendum at varius vel.
                </p> */}
                <div className="flex overflow-hidden mb-4">
                  <img
                    className="inline-block h-10 w-10 rounded-full text-white shadow-solid"
                    src="https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"
                    alt=""
                  />
                </div>
              </Transition>
              <Transition
                show={showIngredients}
                enter="ease-in-out delay-100 duration-100"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-50"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                {product.ingredients && (
                  <p
                    className="leading-relaxed mb-4"
                    dangerouslySetInnerHTML={{
                      __html: product.ingredients.html,
                    }}
                  ></p>
                )}
              </Transition>

              {product.sizesAndPrices && (
                <div className="flex justify-between border-t border-gray-300 py-2 space-x-3">
                  <span className="text-gray-500">Sizes Available</span>
                  <span className="flex space-x-3">
                    {productSizes.map((size, index) => {
                      return (
                        <span
                          key={index}
                          onClick={() => {
                            setPrice(size[1])
                            setActiveSize(size[0])
                          }}
                          className={
                            "cursor-pointer py-1 px-3 rounded  hover:bg-olive-200 hover:text-olive-600 text-xs font-bold tracking-widest uppercase transition duration-150 ease-in-out" +
                            (activeSize === size[0]
                              ? " bg-olive-200 text-olive-600"
                              : " bg-olive-100 text-olive-500")
                          }
                        >
                          {size[0]}
                        </span>
                      )
                    })}
                  </span>
                </div>
              )}
              {/* {product.sizes.length > 0 && (
                <div className="flex justify-between border-t border-gray-300 py-2">
                  <span className="text-gray-500">Sizes Available</span>
                  <span>
                    {product.sizes.map((size, index) => {
                      if (product.sizes.length === index + 1) {
                        return (
                          <span key={index} className="ml-auto text-gray-900">
                            {size}
                          </span>
                        )
                      } else {
                        return (
                          <span key={index} className="ml-auto text-gray-900">
                            {size} |{" "}
                          </span>
                        )
                      }
                    })}
                  </span>
                </div>
              )} */}
              {/* {product.quantity > 0 && (
                <div className="flex border-t  border-gray-300 py-2">
                  <span className="text-gray-500">Quantity Available</span>
                  <span className="ml-auto text-gray-900">
                    {product.quantity}
                  </span>
                </div>
              )} */}
              {price && (
                <div className="flex border-t border-gray-300 mb-6 py-2">
                  <span className="text-gray-500">Price</span>
                  <span className="ml-auto text-gray-900">${price}</span>
                </div>
              )}
              {/* <div className="flex">
                <button className="flex text-white bg-olive-700 border-0 py-2 px-6 focus:outline-none hover:bg-olive-700 rounded">
                  Button
                </button>
                <button className="rounded-full w-10 h-10 bg-gray-200 p-0 border-0 inline-flex items-center justify-center text-gray-500 ml-4">
                  <svg
                    fill="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20.84 4.61a5.5 5.5 0 00-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 00-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 000-7.78z"></path>
                  </svg>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

ProductPage.propTypes = {
  product: PropTypes.object.isRequired,
}

export default ProductPage
